import Index from "./pages/Index.vue";
import Splash from "./pages/Splash.vue";
import Notification from "./pages/Notification.vue";
import Login from "./pages/Login.vue";
export default [
  {
    path: "/",
    component: Splash
  },
  {
    path: "/index",
    component: Index
  },
  {
    path: "/login",
    component: Login
  },
  {
    path: "/notification",
    component: Notification
  }
];
