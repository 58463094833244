<template>
  <f7-page>
    <f7-navbar class="index-header">
      <f7-nav-left>
        <img src="../assets/logo_long.jpeg" class="navbar-logo" />
      </f7-nav-left>
      <f7-nav-right>
        <f7-link
          @click="$f7router.navigate('/notification')"
          icon-only
          class="ml-0"
          icon-f7="bell_fill"
        ></f7-link>
        <f7-link
          icon-only
          class="ml-0"
          @click="logout"
          icon-f7="lock_slash_fill"
        ></f7-link>
      </f7-nav-right>
    </f7-navbar>
     <f7-tabs>
      <f7-tab id="tab-1" tab-active>
      <AppoitmentItem v-for="(item, i) in list" :key="i" :item="item" view="private" />
    </f7-tab>
      <f7-tab id="tab-2">
      <AppoitmentItem v-for="(item, i) in list" :key="i" :item="item" view="public" />
      </f7-tab>
    </f7-tabs>
      <infinite-loading
      ref="infinite"
      @infinite="infiniteHandler"
    ></infinite-loading>

    <f7-toolbar tabbar position="top">
      <f7-link tab-link="#tab-1" @click="reset" tab-link-active>Private</f7-link>
      <f7-link tab-link="#tab-2"  @click="reset" >Public</f7-link>
    </f7-toolbar>
  </f7-page>
</template>
<script>
import InfiniteLoading from "vue-infinite-loading";
import AppoitmentItem from "../components/index/AppointmentItem.vue"
import axios from "axios";
import { APPOITMENTS_URL } from "../assets/js/constants.js";
export default {
  components: {
    InfiniteLoading,
    AppoitmentItem
  },
  data() {
    return {
      next: APPOITMENTS_URL,
      list: []
    }
  },
  computed: {
    token() {
      return this.$store.getters.token;
    }
  },
  methods: {
    logout() {
      const vm = this;
      vm.$f7.dialog.confirm(
        "Are you sure you want to logout?",
        "Confirm",
        () => {
          localStorage.removeItem("token");
          vm.$store.commit("SET_TOKEN", null);
          vm.$f7router.navigate("/login", {
            reloadAll: true
          });
        }
      );
    },
    reset() {
      this.$refs.infinite.stateChanger.reset()
    },
    async infiniteHandler($event) {
      const vm = this;
      console.log(vm.next)
      if(vm.next == null) {
        $event.complete();
        return;
      }
      try {
        let url = vm.next;
        let { token } = vm;
        let headers = {
          Authorization: `Token ${token}`
        };
        let options = { url, method: "get", headers };
        let { data } = await axios(options);
        let { next, results } = data;
        vm.next = next;
        Object.keys(results).forEach(key =>{
          let check = true;
          let item = results[key];
          let {appt_id} = item;
          vm.list.forEach(doc => {
            if(doc.appt_id === appt_id) check = false;
          })
          if(check) vm.list.push(results[key]);
        })
        if (next == null) {
          $event.complete();
        } else {
          $event.loaded();
        }
      } catch (e) {
        console.log(e);
        $event.complete();
        vm.$root.alert(e.message);
      }
    }
  },
  mounted() {}
};
</script>

<style>
.appointment {
  padding: 10px;
  box-shadow: -3px 2px 6px lightgrey !important;
  border-bottom: 1px solid #d3d3d373;
  background: white;
  margin-bottom: 4px;
}
.appointment-details {
  font-size: 16px !important;
  padding-left: 2px;
  padding-bottom: 5px;
  border-bottom: 1px solid #d3d3d373;
}
.navbar-logo {
  width: 100px;
  height: 30px;
  padding-left:10px;
} 
.navbar-bg {
  background:white!important
}
</style>
