import Vue from "vue";
import Vuex from "vuex";
import { FCM_TOKEN_URL } from "../assets/js/constants.js";
import axios from "axios";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    _token: null,
    _device_token: null
  },
  getters: {
    token(state) {
      return state._token;
    },
    device_token(state) {
      return state._device_token;
    }
  },
  mutations: {
    SET_TOKEN(state, val) {
      state._token = val;
    },
    SET_DEVICE_TOKEN(state, val) {
      state._device_token = val;
    }
  },
  actions: {
    async registerToken(context) {
      let { token, device_token } = context.getters;
      if (token == null) return;
      if (device_token == null) return;
      const url = FCM_TOKEN_URL;
      let headers = {
        Authorization: `Token ${token}`
      };
      let data = {
        registration_id: device_token,
        type: "web"
      };
      let options = { url, headers, method: "post", data };
      await axios(options);
    }
  }
});
