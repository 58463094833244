<template>
  <f7-page>
    <f7-page-content class="splash">
      <f7-preloader color="blue"></f7-preloader>
    </f7-page-content>
  </f7-page>
</template>
<script>
export default {
  computed: {
    token() {
      return this.$store.getters.token;
    }
  },
  methods: {
    open() {},
    init() {
      const vm = this;
      let token = localStorage.token;
      vm.$store.commit("SET_TOKEN", token);
      setTimeout(() => {
        if (token == null) {
          vm.$f7router.navigate("/login/");
        } else {
          vm.$f7router.navigate("/index/");
        }
      }, 1000);
    }
  },
  mounted() {
    this.init();
  }
};
</script>
<style scoped>
.splash {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
}
</style>
