import firebase from "firebase/app";
import "firebase/messaging";
import "firebase/database";

var config = {
  apiKey: "AIzaSyA2sobidqUXU76ilkiRnint5naT2oZEyTM",
  authDomain: "markytics-pwa-43748.firebaseapp.com",
  databaseURL: "https://markytics-pwa-43748.firebaseio.com",
  projectId: "markytics-pwa-43748",
  storageBucket: "markytics-pwa-43748.appspot.com",
  messagingSenderId: "310490100022",
  appId: "1:310490100022:web:c97a93def1b6f7e7810397",
  measurementId: "G-0SNVPNHQ4W"
};
firebase.initializeApp(config);

const messaging = firebase.messaging();
const db = firebase.database().ref();

messaging.usePublicVapidKey(
  "BMP_jvqNXEsJUPFgNuut3fmrkNFyNPfYLwFTq2VB6OFvRI61ccCmNOZzTLBEBlaX4CTsmFxYNVNozMLr099DsDE"
);
export { messaging, db };
