<template>
  <f7-page>
    <div class="auth-wrapper">
      <div class="auth-bg">
        <span class="r"></span>
        <span class="r s"></span>
        <span class="r s"></span>
        <span class="r"></span>
      </div>
      <f7-card class="login-card">
        <img src="../assets/logo_long.jpeg" class="login-logo" />
        <f7-list form class="login-list">
          <f7-list-input
            label="Username"
            type="text"
            :value="email"
            required
            validate
            outline
            floating-label
            @input="email = $event.target.value"
          ></f7-list-input>
          <f7-list-input
            label="Password"
            type="password"
            required
            validate
            outline
            floating-label
            :value="password"
            @input="password = $event.target.value"
          ></f7-list-input>
        </f7-list>
        <f7-button class="ma-5" fill @click="submit">Sign In</f7-button>
      </f7-card>
    </div>
  </f7-page>
</template>

<script>
import { APP_NAME, LOGIN_URL } from "../assets/js/constants.js";
import axios from "axios";
export default {
  data() {
    return {
      email: "",
      password: "",
      app_name: APP_NAME
    };
  },
  methods: {
    async submit() {
      const vm = this;
      try {
        let url = LOGIN_URL;
        const { email, password } = vm;
        let username = "";
        let data = { username, email, password };
        if (email === "") return vm.$root.alert("Email is required");
        if (password === "") return vm.$root.alert("Password is required");
        let options = { url, data, method: "post" };
        vm.$root.show();
        let res = await axios(options);
        let { key } = res.data;
        localStorage.token = key;
        vm.$store.commit("SET_TOKEN", key);
        vm.$store.dispatch("registerToken");
        vm.$f7router.navigate("/index", {
          reloadCurrent: true
        });
        vm.$root.hide();
      } catch (_) {
        vm.$root.hide();
        vm.$root.alert("Unable to log in with provided credentials.");
      }
    }
  }
};
</script>
<style>
.login-logo {
  width:100%;
  height:50px;
  object-fit:contain;
  margin-bottom: 10px;
}
.login-card {
  position: absolute;
  width: 90%;
  height: 300px;
  padding-top: 20px;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}
.login-list {
  margin-bottom: 15px !important;
}
.auth-wrapper {
  background: #f4f7fa;
  width: 100%;
  height: 100vh;
}
.auth-wrapper .auth-bg .r {
  position: absolute;
  width: 300px;
  height: 300px;
  border-radius: 50%;
}
.auth-wrapper .auth-bg .r:first-child {
  top: -100px;
  right: -100px;
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
}
.auth-wrapper .auth-bg .r:last-child {
  left: -100px;
  bottom: -100px;
  background: linear-gradient(-135deg, #899fd4 0%, #a389d4 100%);
}
.auth-wrapper .auth-bg .r.s {
  width: 20px;
  height: 20px;
}
.auth-wrapper .auth-bg .r.s:nth-child(2) {
  top: 150px;
  right: -150px;
  background: #04a9f5;
}
.auth-wrapper .auth-bg .r.s:nth-child(3) {
  left: -150px;
  bottom: 150px;
  background: #1de9b6;
}
.auth-wrapper .auth-bg .r:nth-child(odd) {
  -webkit-animation: floating 7s infinite;
  animation: floating 7s infinite;
}
.auth-wrapper .auth-bg .r:nth-child(even) {
  -webkit-animation: floating 9s infinite;
  animation: floating 9s infinite;
}
@-webkit-keyframes floating {
  from {
    -webkit-transform: rotate(0deg) translate(-10px) rotate(0deg);
    transform: rotate(0deg) translate(-10px) rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg) translate(-10px) rotate(-360deg);
    transform: rotate(360deg) translate(-10px) rotate(-360deg);
  }
}
@keyframes floating {
  from {
    -webkit-transform: rotate(0deg) translate(-10px) rotate(0deg);
    transform: rotate(0deg) translate(-10px) rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg) translate(-10px) rotate(-360deg);
    transform: rotate(360deg) translate(-10px) rotate(-360deg);
  }
}
</style>
