import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import store from "./store";
// eslint-disable-next-line no-unused-vars
import css from "framework7/css/framework7.bundle.min.css";
import routes from "./router";
// eslint-disable-next-line no-unused-vars
import icons from "framework7-icons/css/framework7-icons.css";
import Framework7 from "framework7/framework7-lite.esm.bundle.js";
import Framework7Vue from "framework7-vue/framework7-vue.esm.bundle.js";
import VueOffline from 'vue-offline'
Framework7.use(Framework7Vue);

Vue.use(VueOffline)

Vue.config.productionTip = false;
new Vue({
  store,
  data() {
    return {
      f7params: {
        routes,
        name: "Markytics"
      }
    };
  },
  methods: {
    alert(text) {
      this.$f7.dialog.alert(text);
    },
    show() {
      this.$f7.dialog.preloader();
    },
    hide() {
      this.$f7.dialog.close();
    }
  },
  render: h => h(App)
}).$mount("#app");
