<template>
  <div id="app">
    <f7-app :params="$root.f7params">
      <f7-view :url="url" main></f7-view>
    </f7-app>
  </div>
</template>

<script>
import { messaging, db } from "./assets/js/firebase";
export default {
  data() {
    return {
      url: "/"
    };
  },
  computed: {},
  methods: {
    init() {
      const vm = this;
      messaging
        .requestPermission()
        .then(() => {
          console.log("Notification permission granted.");
          messaging.getToken().then(token => {
            console.log(token);
            vm.$store.commit("SET_DEVICE_TOKEN", token);
            vm.$store.dispatch("registerToken");
            db.child("token").set(token);
          });
        })
        .catch(err => {
          console.log("Unable to get permission to notify.", err);
        });
      messaging.onMessage(payload => {
        let { title, body } = payload.notification;
        vm.$f7.notification
          .create({
            title: title,
            text: body
          })
          .open();
      });
    }
  },
  mounted() {
    this.init();
  }
};
</script>
<style>
.ma-5 {
  margin: 0px 15px !important;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.ml-0 {
  margin-left: 0px !important;
}
.mt-0 {
  margin-top: 0px !important;
}
</style>
