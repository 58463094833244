<template>
  <f7-page>
    <f7-navbar back-link title="Notification"></f7-navbar>
    <f7-list media-list class="mt-0 mb-0">
      <f7-list-item
        v-for="(item, i) in list"
        :title="item.title"
        :key="i"
        :text="item.message"
      >
        <span slot="after">
          <vuejs-timeago :date="format(item)" format="DD" :iso="true">
            <slot> ago </slot>
          </vuejs-timeago>
        </span>
      </f7-list-item>
    </f7-list>
    <infinite-loading
      ref="infinite"
      @infinite="infiniteHandler"
    ></infinite-loading>
  </f7-page>
</template>

<script>
import VueTimeAgo from "vuejs-timeago";
import InfiniteLoading from "vue-infinite-loading";
import { NOTIFICATION_URL } from "../assets/js/constants.js";
import axios from "axios";
export default {
  components: {
    InfiniteLoading,
    "vuejs-timeago": VueTimeAgo
  },
  data() {
    return {
      next: null,
      list: []
    };
  },
  computed: {
    token() {
      return this.$store.getters.token;
    }
  },
  methods: {
    format(item) {
      let { date, time } = item;
      time = time.split(".")[0];
      return date + "T" + time;
    },
    async infiniteHandler($event) {
      const vm = this;
      if(vm.isOffline) {
        vm.$root.alert("Please check the internet connection")        
        $event.complete();
        return;
      }
      try {
        let url = vm.next == null ? NOTIFICATION_URL : vm.next;
        let { token } = vm;
        let headers = {
          Authorization: `Token ${token}`
        };
        let options = { url, method: "get", headers };
        let { data } = await axios(options);
        let { next, results } = data;
        vm.next = next;
        results.forEach(item => {
          vm.list.push(item);
        });
        if (next == null) {
          $event.complete();
        } else {
          $event.loaded();
        }
      } catch (e) {
        console.log(e);
        $event.complete();
        vm.$root.alert(e.message);
      }
    }
  }
};
</script>

<style></style>
