<template>
    <div class="appointment" v-if="isVisible" >
      <div class="appointment-details">
        <b>#{{item.appt_id}}</b>
        <br />
        {{item.patient}}
        <br />
      </div>
      <table>
        <tr>
          <td>Nurse</td>
          <td>: {{item.nurse}}</td>
        </tr>
        <tr>
          <td>Center</td>
          <td>: {{item.center}}</td>
        </tr>
        <tr>
          <td>Doctor</td>
          <td>: {{item.doctor}} ({{item.speciality}})</td>
        </tr>
        <tr>
          <td>Status</td>
          <td>: {{item.status}}</td>
        </tr>
        <tr>
          <td>Date</td>
          <td>: {{date}}</td>
        </tr>
      </table>
    </div>
</template>
<script>
import moment from "moment";
export default {
    props: {
        item :{ 
            type: Object,
            default: () =>{
                return {}
            }
        },
        view:{ 
            type: String,
            default: "private"
        }
    },
    computed: {
        date() {
            let {appt_datetime} = this.item;
            return moment(appt_datetime,"HH:mm:ss YYYY-MM-DD").format("YYYY-MM-DD HH:mm:ss")           
        },
        isVisible() {
            let {item, view} = this;
            let {is_private} = item;
            if(view === "private" && is_private === true) return true;
            if(view === "public" && is_private === false) return true;
            return false;
        }
    }
};
</script>

<style>
.appointment {
  padding: 10px;
  box-shadow: -3px 2px 6px lightgrey !important;
  border-bottom: 1px solid #d3d3d373;
  background: white;
  margin-bottom: 4px;
}
.appointment-details {
  font-size: 16px !important;
  padding-left: 2px;
  padding-bottom: 5px;
  border-bottom: 1px solid #d3d3d373;
}
</style>
